import { fetch } from 'domain-task';

import {BaseApplicationState} from '@common/react/store';
import {BaseUser} from '@common/react/objects/BaseUser';
import {BaseParams} from '@common/typescript/objects/BaseParams';
import { Redirect } from 'react-router';

interface Message<T> {
	success: number;
	response: T;
	session: string;
}

interface Error {
	message: string;
	code: number;
}

export function request<T, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>
>(type: string, data: BaseParams = {}, state?: TApplicationState): Promise<T> {
	return fetch('api/post', {
		credentials: 'same-origin',
		method: 'POST',
		headers: {
			'Content-type': 'application/json; charset=utf-8',
			Cookie: `session=${state ? state.login.session : ''}`
		},
		body: JSON.stringify({
			type,
			data: JSON.stringify(data)
		})
	})
	.then(response => response.json())
	.then((data: Message<T>) => {
		if (!data.success) {
			console.log('data.success', data)
			return data.response;
		}
		
		return data.response;
	}).catch((error: Error) => {
		
		console.log(error);
		throw error.message;
	});
}


export function allRequest<
    TUser extends BaseUser,
    TApplicationState extends BaseApplicationState<TUser>
    >(url: string, method: string, data: BaseParams = {}, state?: TApplicationState): Promise<any> {
    
    return fetch(url, {
        credentials: 'same-origin',
        method: method,
        headers: {
            'Content-type': 'application/json; charset=utf-8',
            Cookie: `session=${state ? state.login.session : ''}`
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then((data) => {
        if (!data.success) {
            throw data.response;
        }
		debugger
        return data.response;
	}).catch((error: Error) => {
		debugger
        console.log(error.message);
        throw error.message;
    });
}