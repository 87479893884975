import * as React from "react";
import {
	actualTimesObject,
	Call,
	CallFile,
	CallType,
	getTimesFromCalls,
	playCallVoice,
	WeekDayCalls
} from "@app/objects/Call";

import {minutesToHours} from "@app/components/Utils/Utils";
import {Link} from "react-router-dom";
import {getEventWorkTimeString, getScheduleRangeString} from "@app/objects/Event";
import * as moment from "moment";
import {List} from "@common/typescript/objects/List";
import { request } from '@app/components/Api';
import { allRequest } from '@app/components/Api';
import {SortingDirection} from "@common/react/components/Pages/ItemsPage";
import {BaseParams} from "@common/typescript/objects/BaseParams";
import CallListEditablePopup from "@app/components/Pages/Admin/Calls/CallListEditablePopup";
import { isExternalUser } from "@app/objects/User";
import { Injury } from "@app/objects/Injury";

interface ComponentProps {
	calls: WeekDayCalls[];
	timeFormat: 24 | 12;
	onEdit?: (id: number[]) => void;
	onVoicePlay?: (message: CallFile, date: number) => void;
	onSort?: (sortParams: BaseParams[]) => void;
	onRefreshCalls: () => void;
	isNotExternal: boolean;
	isLoading?: boolean;
}

interface ComponentState {
	isPopupVisible: boolean;
	callsByDate: List<Call> | null;
	injuryId: number;
	startTime: number;
	sorting: {
		[key: string]: SortingDirection;
    },
    injury: Injury | undefined|null;
}

interface ByDateGroupObject {
	value: number;
    injuries: ByInjuryGroupObject[];
    enableDaylightTimeSaving: boolean;
}

interface ByInjuryGroupObject {
	value: number;
	calls: Call[]
}

type RowProps = {
    calls: Call[];
    enableDaylightTimeSaving: boolean;
	timeFormat: 24 | 12;
    onEdit?: (id: number[]) => void;
    onShow: (id: number, time: number, injry: Injury | undefined|null) => void;
	onVoicePlay: (message: CallFile, date: number) => void;
	date: number;
	showLink: boolean;
}

function groupBy(arr: any[] = [], getProp, itemsPropName: string = 'items') {
	let indexes: any[] = [];

	return arr.reduce(function(groups, item) {
		const val = getProp(item),
			index = indexes.indexOf(val);

		if(index !== -1) {
			groups[index][itemsPropName].push(item);
		} else {
			groups.push({value: val, [itemsPropName]: [item]});
			indexes.push(val);
		}

		return groups
	}, []);
}


const convertTimeToEmployeeTimeZone = (time,call,extraDaylighttime = 0)=> {
    //return moment.utc(call.time);
    //console.log(call,time, moment.utc(time), moment.utc(time).subtract(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours'))
    if (call.manual) extraDaylighttime = 0;
    if (call.employee != null && call.employee.timeZone > 0)
        return moment.utc(time).add(call.employee != null ? call.employee.timeZone + extraDaylighttime : 0, 'hours')
    else if (call.employee != null && call.employee.timeZone < 0)
        return moment.utc(time).subtract(call.employee != null ? (call.employee.timeZone + extraDaylighttime )* -1  : 0, 'hours')
    else
        return moment.utc(time);
}

const getActualTimeString = (calls: Call[], militaryTime?: boolean, enableDaylightTimeSaving?: boolean): string | JSX.Element => {

    //console.log(calls);
    const times: actualTimesObject = getTimesFromCalls(calls);
    var enableDaylight = enableDaylightTimeSaving ? 1 : 0;
    //times.startTime = calls[0].time;
    //times.endTime = calls[calls.length - 1].time;
	if (times.startTime == -62135596800000 && times.endTime == null) return '';
	
	const duration = calls.reduce((acc, call) => acc + call.duration, 0);
	//debugger
	if(times.startTime) {
        return <React.Fragment>
            {times.startTime && times.startCall != undefined ? convertTimeToEmployeeTimeZone(times.startTime, times.startCall, enableDaylight).format(militaryTime ? 'HH:mm' : 'h:mm a') : null} - {times.endTime && times.endCall != undefined ? convertTimeToEmployeeTimeZone(times.endTime, times.endCall, enableDaylight).format(militaryTime ? 'HH:mm' : 'h:mm a') : <strong className="red-color">?</strong>} ({duration ? minutesToHours(duration) : <strong className="red-color">0</strong>})
		</React.Fragment>
	} else {
		return '';
	}
};

const getVoiceMessages = (calls: Call[], all: boolean = false) => calls.reduce((messages: CallFile[], item: Call) => {
		if(item.callType === CallType.Record && item.files && (all ? true : !item.read)) {
			messages = [...messages, ...item.files.list]
		}
		
		return messages;
	}, 
[]);

const hasUnauthorized = (calls: Call[]) => calls.some((call: Call) => call.companyPhoneId === null && call.callType != CallType.Fake);

const hasManual = (calls: Call[]) => calls.some((call: Call) => call.manual && call.callType != CallType.Fake);
const hasCalls = (calls: Call[]) => calls.some((call: Call) => call.callType != CallType.Fake);

const CallRow: React.SFC<RowProps> = ({ calls, timeFormat, enableDaylightTimeSaving, onEdit, onShow, onVoicePlay, date, showLink}) => {
	if(calls.length === 0)
		return null;

	const firstCall = calls[0];
	
	
	const unreadVoiceMessages = getVoiceMessages(calls);
	const allVoiceMessagesCount = getVoiceMessages(calls, true).length;
    function handleChange(evt, event) {

        if (event == null) {
            alert("No event selected"); return;
        }

        const target = evt.target as HTMLSelectElement;

        allRequest('updateEventNote?eventId=' + event.id + '&note=' + target.value, 'Get', null).then((result) => {
            if (!result) {
                alert("cannot save comment");
            }
            else {
                gCallsTable.props.onRefreshCalls();
            }
        });


    };
	return <tr key={firstCall.id}>
		{/*<td>{firstCall.injury && firstCall.injury.employe ? getUserName(firstCall.injury.employe) : ''}</td>*/}
		<td>{firstCall.employeeName || ''}</td>
		<td>{firstCall.injury && firstCall.employeeId ? 
			(showLink ? <Link to={`/employe-editor/${firstCall.employeeId}?case=${firstCall.injury.vccCase}`}>{firstCall.injury.vccCase}</Link> : firstCall.injury.vccCase) 
			: ''}
		</td>
		{/*<td>{firstCall.employee && firstCall.employee.company ? firstCall.employee.company.name : ''}</td>*/}
		<td>{firstCall.employerName}</td>
        <td>{hasCalls(calls) ? (hasManual(calls) ? 'M' : hasUnauthorized(calls) ? 'U' : 'A'):''}</td>
		<td>{firstCall.event && firstCall.event.company ? firstCall.event.company.name : ''}</td>
		<td>{firstCall.event &&
			<div>{getScheduleRangeString(firstCall.event, timeFormat === 24)} <br/> ({getEventWorkTimeString(firstCall.event)})</div>
		}</td>
		<td>
            {getActualTimeString(calls, timeFormat === 24, enableDaylightTimeSaving)}
		</td>
        {/*<td>{firstCall.event && getTimeDifference(firstCall.event, times)}</td>*/}
        <td>{firstCall.event && gCallsTable.props.isNotExternal? <select value={firstCall.event.note} className="form-control" onChange={(e) => {
            handleChange(e, firstCall != null && firstCall.event!=null? firstCall.event:null)
        }}>
            <option value={""}></option>
            <option value={"Called Out-Personal"}>Called Out-Personal</option>
            <option value={"Child Issues"}>Child Issues</option>
            <option value={"Early Departure-Medical"}>Early Departure-Medical</option>
            <option value={"Early Departure-Personal"}>Early Departure-Personal</option>
            <option value={"Excused Time"}>Excused Time</option>
            <option value={"Holiday Closure"}>Holiday Closure</option>
            <option value={"Last Date"}>Last Date</option>
            <option value={"Late Arrival-Medical"}>Late Arrival-Medical</option>
            <option value={"Late Arrival-Personal"}>Late Arrival-Personal</option>
            <option value={"Lunch Break-Medical"}>Lunch Break-Medical</option>
            <option value={"Lunch Break-Personal"}>Lunch Break-Personal</option>
            <option value={"No Show"}>No Show</option>
            <option value={"NWPT-NP Closed"}>NWPT-NP Closed</option>
            <option value={"NWPT-NP Closed Early"}>NWPT-NP Closed Early</option>
            <option value={"NWPT-NP Sent AA home"}>NWPT-NP Sent AA home</option>
            <option value={"Off Schedule"}>Off Schedule</option>
            <option value={"Orientation Date"}>Orientation Date</option>
            <option value={"Personal-Medical"}>Personal-Medical</option>
            <option value={"Schedule Updated"}>Schedule Updated</option>
            <option value={"Sick"}>Sick</option>
            <option value={"Start Date"}>Start Date</option>
            <option value={"UPT"}>UPT</option>
            <option value={"Vacation"}>Vacation</option>
            <option value={"NP Closed"}>NP Closed</option>
            <option value={"NP Closed Early"}>NP Closed Early</option>
            <option value={"NP Sent EE Home"}>NP Sent EE Home</option>
        </select> : (firstCall.event && !gCallsTable.props.isNotExternal ? firstCall.event.note : '')}</td>
		<td>
			<div className="table-actions">
				{allVoiceMessagesCount > 0 &&
					<React.Fragment>
						{unreadVoiceMessages.length > 0
							? <button className="btn btn-sm btn-danger" type="button" title="Play record" onClick={e => onVoicePlay(unreadVoiceMessages[0], date)}>V<span className="badge">{unreadVoiceMessages.length}</span></button>
							: <button className="btn btn-sm btn-default" type="button">V<span className="badge">{allVoiceMessagesCount}</span></button>}
					</React.Fragment>	
				}
				<button className="btn btn-sm btn-default" type="button" title="Show all" onClick={e => onShow(firstCall.injuryId, date, firstCall.injury)}>
					<i className="fa fa-eye"></i>
				</button>
				{/*{onEdit &&
					<button className="btn btn-sm btn-default" type="button" title="Edit" onClick={e => onEdit(calls.map(call => call.id))}>
						<i className="fa fa-pencil"></i>
					</button>
				}*/}
			</div>
		</td>
	</tr>;
};
var gCallsTable: CallsTable ;
export class CallsTable extends React.Component<ComponentProps, ComponentState>  {
	constructor(props) {
		super(props);
        gCallsTable = this;
		this.state = {
			isPopupVisible: false,
			callsByDate: null,
			injuryId: 0,
			startTime: 0, 
			sorting: {
				case: SortingDirection.Default,
				employee: SortingDirection.Default,
				employer: SortingDirection.Default
            },
            injury: null
		};
		
		this.onCancel = this.onCancel.bind(this);
		this.loadCalls = this.loadCalls.bind(this);
		this.onVoicePlay = this.onVoicePlay.bind(this);
		this.customSort = this.customSort.bind(this);
		this.onRefreshCall = this.onRefreshCall.bind(this);
	}

    loadCalls(id: number, time: number, injury: Injury | undefined|null) {
		this.setState({
			injuryId: id,
            startTime: time,
            injury: injury
		});
        console.log(injury);
        const start = moment.utc(time);

		request<List<Call>>('callList', {
			objectId: id,
			from: +start,
			withFiles: true,
            withSorting: true, 
            WithEmployee: true,
			count: 50
		}).then((result) => {
			console.log("result", result)
			this.setState({
				isPopupVisible: true,
				callsByDate: result
			})
		});
	}
	
	onRefreshCall(id: number, time: number) {
        this.props.onRefreshCalls();
        this.loadCalls(id, time, this.state.injury);
	}
	
	onCancel() {
		this.setState({
			isPopupVisible: false,
		})
	}
	
	onVoicePlay(message: CallFile, date: number) {
		playCallVoice(message);
		this.props.onVoicePlay && this.props.onVoicePlay(message, date);
	}
	
	customSort(field: string) {
		const sort = this.state.sorting[field] === SortingDirection.Descending ? SortingDirection.Default : this.state.sorting[field] + 1;
		
		this.setState({
			sorting: {
				employee: SortingDirection.Default,
				case: SortingDirection.Default,
				employer: SortingDirection.Default,
				[field]: sort
			}
		});
		
		this.props.onSort && this.props.onSort(sort === SortingDirection.Default ? [] : [{
			caption: field,
			direction: sort
		}]);
	}
	
	getSortingColumn(caption: string, field: string): JSX.Element {
		const sort: SortingDirection = this.state.sorting[field];
		
		return <th className={`ant-table-column-has-actions ant-table-column-has-sorters ${sort !== SortingDirection.Default ? 'ant-table-column-sort' :''}`} onClick={e => this.customSort(field)}>
			<div className="ant-table-column-sorters">{caption}</div>
			<div className="ant-table-column-sorter">
				<i className={`anticon anticon-caret-up ant-table-column-sorter-up ${sort === SortingDirection.Ascending ? 'on' : 'off'}`}>
					<svg viewBox="0 0 1024 1024" className="" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>
				</i>
				<i className={`anticon anticon-caret-down ant-table-column-sorter-down ${sort === SortingDirection.Descending ? 'on' : 'off'}`}>
					<svg viewBox="0 0 1024 1024" className="" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>
				</i>
			</div>
		</th>
	}
	
    render() {
		const newItems: ByDateGroupObject[] = this.props.calls.map((item, index) => ({
			value: item.date,
			injuries: groupBy(
				item.calls,
				(item) => item.injuryId,
                'calls'),
            enableDaylightTimeSaving: item.enableDaylightTimeSaving
		})).sort((a, b) => a.value - b.value);
		return <div className={`ant-table calls-page__table is-relative ${this.props.isLoading ? 'ant-spin-blur"' : ''}`}>
			{this.props.isLoading &&
				<div className="ant-spin-nested-loading">
					<div>
						<div className="ant-spin ant-spin-spinning ant-table-without-pagination ant-table-spin-holder" style={{minHeight: '200px'}}><span
							className="ant-spin-dot ant-spin-dot-spin"><i></i><i></i><i></i><i></i></span></div>
					</div>
				</div>
			}
			<table>
				<thead className="ant-table-thead">
				<tr>
					{this.getSortingColumn('Employee', 'employee')}
					{this.getSortingColumn('Case #', 'case')}
					{this.getSortingColumn('Employer', 'employer')}
					<th>A/U</th>
					<th>Worksite</th>
					<th>Scheduled Hours</th>
                        <th>Actual Hours</th>
                        <th className="mw300">Comments</th>
					{/*<th>Difference</th>*/}
					<th></th>
				</tr>
				
				</thead>
				<tbody className="ant-table-tbody">
						{newItems.map((date: ByDateGroupObject) => (
							<React.Fragment key={date.value}>
								<tr className="date-row">
									<td colSpan={9}>
										{moment(date.value).format(`dddd, MM/DD/YY`)}
									</td>
								</tr>
								{date.injuries.length > 0
									? date.injuries.map((item: ByInjuryGroupObject) => (
										<CallRow 
											key={item.value} 
											timeFormat={this.props.timeFormat} 
                                            calls={item.calls} 
                                            enableDaylightTimeSaving={date.enableDaylightTimeSaving}
											onEdit={this.props.onEdit} 
											onShow={this.loadCalls} 
											onVoicePlay={this.onVoicePlay}
											date={date.value}
											showLink={this.props.isNotExternal}
										/>
									))
									: <tr><td colSpan={8}><div className="text-center">No calls</div></td></tr>
								}
							</React.Fragment>
						))}
				</tbody>
			</table>
			{/*<CallListPopup 
				calls={this.state.callsByDate} 
				visible={this.state.isPopupVisible} 
				onCancel={this.onCancel}
			/>*/}
			<CallListEditablePopup
                calls={this.state.callsByDate}
                visible={this.state.isPopupVisible}
                onCancel={this.onCancel}
                onListChange={this.onRefreshCall}
                vccCase={this.state.injury ? this.state.injury.vccCase : ''}
                employeeName={this.state.injury ? this.state.injury.employe.lastName + ' ' + this.state.injury.employe.firstName : ''}
			/>
		</div>
	}
}
